<template>
  <div class="page"
       @touchmove="(e)=>{e.preventDefault()}">
    <div class="s1">
      <div class="wrap">
        <div class="flex fs-16 px-3">
          <div>标题</div>
          <div class="flex-1"><input v-model="form.msgTitle"
                   type="text"
                   placeholder="请输入链接标题"
                   class="inp"
                   maxlength="10"></div>
        </div>
        <div class="divider ml-3" />
        <div class="flex fs-16 p-3">
          <div class="flex-1">封面</div>
          <div>
            <div v-if="form.msgContent"
                 class="avatar">
              <img :src="form.msgContent"
                   mode="aspectFill"
                   class="img">
              <div class="del"
                   @click="form.msgContent=''" />
            </div>
            <div v-else
                 class="avatar add"><input class="file"
                     type="file"
                     @change="uploadImg" /></div>
          </div>
        </div>
        <div class="divider ml-3" />
        <div class="flex fs-16 px-3">
          <div>简介</div>
          <div class="flex-1"><input v-model="form.msgDes"
                   type="text"
                   placeholder="请输入链接的描述"
                   class="inp"></div>
        </div>
        <div class="divider ml-3" />
        <div class="flex fs-16 px-3">
          <div>地址</div>
          <div class="flex-1"><input v-model="form.msgHref"
                   type="text"
                   placeholder="请输入链接的http地址"
                   class="inp"></div>
        </div>
      </div>
      <div class="fs-12 mt-2 text-right color-gray">提示：封面图片只能选择一张且大小不能超过2M</div>
    </div>

    <div class="p-footer h-end">
      <button class="btn auto"
              @click="goBack()">取消</button>
      <button class="btn auto fill ml-3"
              @click="submit">保存</button>
    </div>
  </div>
</template>

<script>
import { ossUploadFile } from '@/utils/index'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      form: {
        msgType: 2005,
        msgTitle: '',
        msgContent: '',
        msgDes: '',
        msgHref: ''
      }
    }
  },
  computed: {
    ...mapGetters(['globalData', 'contentData'])
  },
  created () {

  },
  mounted () {
    const pick = this.globalData
    if (pick.msgType === 2005) {
      this.form.msgTitle = pick.msgTitle;
      this.form.msgDes = pick.msgDes;
      this.form.msgContent = pick.msgContent;
      this.form.msgHref = pick.msgHref;
    }
  },
  methods: {
    uploadImg (e) {
      if (e.target.files.length && e.target.files.length > 1) {
        this.$Toast('只能选择单张图片')
        return
      }
      if (e.target.files.length) {
        const file = e.target.files[0]
        const isImage = file.type.indexOf('image') > -1;
        if (!isImage) {
          this.$Toast('请选择正确的图片格式！')
          return
        }
        ossUploadFile(file, 1).then(res => {
          if (res) {
            this.form.msgContent = res
          }
        })
      }
    },
    validData () {
      const _this = this
      if (!_this.form.msgTitle) {
        this.$Toast('请输入链接标题')
        return false
      }
      if (!_this.form.msgContent) {
        this.$Toast('请选择封面图片')
        return false
      }
      if (!_this.form.msgDes) {
        this.$Toast('请输入链接的描述')
        return false
      }
      if (!_this.form.msgHref) {
        this.$Toast('请输入链接的http地址')
        return false
      }
      return true
    },
    goBack () {
      this.$router.go(-1)
    },
    submit () {
      if (!this.validData()) {
        return
      }
      this.$store.commit('setGlobalData', {
        link: this.form
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
